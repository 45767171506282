import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Package } from '@imunify360-api/license';
import { AgentResponse, AgentStatus } from '@imunify360-api/misc';
import { InstallationBackendService } from 'app/components/installation/installation-backend.service';
import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { postOnly } from './utils';
import { Panel } from 'app/utils/panel';
import { NotificationsService } from 'app/services/notifications';

const systemErrorMessage = 'System error 2: No such file or directory';
const importErrorMessage = 'Error: No module named';
const logTabUrl =  `/installation/progress/log`;
const slidesTabUrl = `/installation/progress/slides`;

@Injectable()
export class ImunifyStatusInterceptor implements HttpInterceptor {
    private getInstallationStatus: Observable<any>;

    constructor(
        private router: Router,
        private injector: Injector,
        private appState: AppState,
        private panel: Panel,
        public notifications: NotificationsService,
    ) {}

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // NOTE: The route at the moment of the request matters, not at the moment of the response.
        // We want to cancel all requests that get non-OK status, except for the ones originated
        // on the installation page itself.
        const route = this.router.url;
        return next.handle(req).pipe(
            catchError((error) => {
                try {
                    if (error.error.includes(importErrorMessage)) {
                        if (this.appState.imunifyStatus.value !== AgentStatus.INSTALLING) {
                            const r = this.updateAndCheckStatus(AgentStatus.NOT_INSTALLED, route);
                            if (r) return r;
                        }
                    }
                    if (error.error.includes(systemErrorMessage)) {
                        if (this.injector.get(AuthState).isClient.value) {
                            this.updateAndCheckStatus(AgentStatus.INSTALLING, route);
                            return EMPTY;
                        }
                        if (!this.getInstallationStatus) {
                            this.getInstallationStatus = this.injector
                                .get(InstallationBackendService).status({
                                    offset: 0,
                                    limit : 1,
                                }).pipe(share());
                        }
                        this.getInstallationStatus.subscribe();
                        return EMPTY;
                    }
                } catch {}
                throw error;
            }),
            switchMap((event) => {
                if (event instanceof HttpResponse) {
                    const res: AgentResponse<any> = event.body;
                    let status: AgentStatus;
                    if (
                        typeof res.status === 'string'
                        && Object.values(AgentStatus).includes(res.status)
                    ) {
                        status = res.status;
                    } else {
                        status = AgentStatus.OK;
                        if ((res.status as any) === 200) {
                            // FIXME: Imunify Email returns HTTP status code instead of AgentStatus
                        } else if (res.status) {
                            console.warn(
                                'unexpected value for the `status` field in the response:',
                                res.status,
                            );
                        }
                    }
                    const result = this.updateAndCheckStatus(status, route);
                    this.updateStatusCode((res.status as any));
                    if (result) return result;
                }

                return of(event);
            }),
        );
    }


    reload() {
        location.reload();
    }
    /**
     * This method was extracted from previous one because one more place of usage was needed
     * `return EMPTY` means return from parent method. And `return;` looks like analog of `break;`
     */
    private updateAndCheckStatus(status: AgentStatus, route: string): Observable<never>|undefined {
        if (this.appState.imunifyStatus.value !== status) {
            this.appState.imunifyStatus.next(status);
        }
        if (this.checkStandaloneWarning(status)) {
            this.router.navigateByUrl('/standalone-warning', { replaceUrl: true });
            return EMPTY;
        }
        switch (this.appState.imunifyStatus.value) {
            case AgentStatus.OK:
                if (route.startsWith('/installation')) {
                    this.router.navigateByUrl('/', { replaceUrl: true }).then(() => this.reload());
                }
                return;
            case AgentStatus.INSTALLING:
                if (!route.startsWith('/installation')) {
                    this.router.navigateByUrl(slidesTabUrl, { replaceUrl: true });
                    return EMPTY;
                }
                return;
            case AgentStatus.SOCKET_INACCESSIBLE:
            case AgentStatus.NOT_INSTALLED:
                if (route !== '/installation') {
                    if (this.injector.get(AuthState).isClient.value) {
                        this.router.navigateByUrl(slidesTabUrl, {replaceUrl: true});
                    } else {
                        this.router.navigateByUrl('/installation', {replaceUrl: true});
                    }
                    return EMPTY;
                }
                return;
            case AgentStatus.FAILED_TO_INSTALL:
                if (IMUNIFY_PACKAGE === Package.imunify360) {
                    if (route !== logTabUrl) {
                        if (this.injector.get(AuthState).isClient.value) {
                            this.router.navigateByUrl(slidesTabUrl, {replaceUrl: true});
                        } else {
                            this.router.navigateByUrl(logTabUrl, { replaceUrl: true });
                        }
                        return EMPTY;
                    }
                }
                return;
            case AgentStatus.STOPPED:
                this.router.navigate(['installation', 'stopped-service'], {
                    replaceUrl: true,
                });
                return EMPTY;
            default:
                return;
        }
    }

    checkStandaloneWarning(status: AgentStatus): boolean {
        return this.panel.isNoPanel &&
            (status === AgentStatus.NOT_INSTALLED || status === AgentStatus.SOCKET_INACCESSIBLE);
    }

    updateStatusCode(status: number) {
        if (this.appState.responseStatusCode.value !== status) {
            this.appState.responseStatusCode.next(status);
        }
    }
}
