import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AgentStatus } from '@imunify360-api/misc';
import { ZonedBehaviorSubject } from 'app/utils/rxjs-zoned';

@Injectable()
export class AppState {
    version = VERSION;
    csf: boolean;
    imunifyStatus: ZonedBehaviorSubject<AgentStatus> = new ZonedBehaviorSubject(AgentStatus.OK);
    responseStatusCode: ZonedBehaviorSubject<number> = new ZonedBehaviorSubject(200);
    refresherEnabled = false;
    mousePositonY: number = 0;
    demo: boolean = false; // Server is running in demo mode: dashboard requests are mocked etc
    lang: ZonedBehaviorSubject<string|null> = new ZonedBehaviorSubject(null);
    notifications: ZonedBehaviorSubject<Notification[]> = new ZonedBehaviorSubject([]);
    overlayContainer: HTMLElement;
    private _sharedObservables: {[key: string]: Observable<any>} = {};
    private _sharedAdditionalChanges: {[key: string]: Subject<any>} = {};
    private _sharedCacheObservables: {[key: string]: Observable<any>} = {};
    private _sharedCacheReset: {[key: string]: Subject<any>} = {};
}

export class Notification {
    type: string;
    title: string;
    content: string;
    counter: number = 0;
    ticks: number = 0;

    constructor(item: {type: string, title: string, content: string}) {
        this.type = item.type;
        this.title = item.title;
        this.content = item.content;
    }
}
